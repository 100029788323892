<template>
  <div class='border-t bg-white flex flex-col py-12'>
    <div class='layout-container w-full bg-white flex flex-col lg:flex-row lg:gap-x-8 gap-y-8 justify-between px-4 lg:px-0 text-xs lg:text-sm text-gray-700'>
      <div>
        <div class='font-semibold text-sm lg:text-base text-gray-900'>Hosted by Organizing Committee of the Korean Orthopaedic Association</div>
        <div class='mt-4 text-sm flex flex-col lg:flex-row justify-start'>
          <div class='uppercase inline-block opacity-70 w-full lg:w-72'>President</div>
          <div>Myung Ku Kim, MD, PhD.</div>
        </div>
        <div class='mt-4 text-sm flex flex-col lg:flex-row justify-start'>
          <div class='uppercase inline-block opacity-70 w-full lg:w-72'>Contact Number</div>
          <div>+82-2-780-2766</div>
        </div>
        <div class='mt-4 text-sm flex flex-col lg:flex-row justify-start'>
          <div class='uppercase inline-block opacity-70 w-full lg:w-72'>Email</div> 
          ortho@koa.or.kr
        </div>
        <div class='mt-4 text-sm flex flex-col lg:flex-row justify-start'>
          <div class='uppercase inline-block opacity-70 w-full lg:w-72'>Fax</div> 
          +82-2-780-2767
        </div>
        <div class='mt-4 text-sm flex flex-col lg:flex-row justify-start'>
          <div class='uppercase inline-block opacity-70 w-full lg:w-72'>Address</div> 
           A-905, Centreville Asterium Seoul, 372 Hangang-daero, Yongsan-gu Seoul, 04323, Korea
        </div>
        <div class='mt-4 text-sm flex flex-col lg:flex-row justify-start'>
          <div class='uppercase inline-block opacity-70 w-full lg:w-72'>Business Registration Number</div> 
          106-82-31262
        </div>
        <div class='mt-4 text-sm flex flex-row justify-start'>
          © 2022 Korean Orthopaedic Association
        </div>
      </div>
      <div class='flex flex-col lg:justify-start lg:items-start justify-center items-center'>
        <img :src='eventConfigLogoImageUrl'
             class='h-12 cursor-pointer mb-6 w-auto'
             @click='goToHome'/>
        <div
          v-if='hasValidToken' 
          class='text-left uppercase border px-4 py-2 text-center rounded-md hover:shadow-md cursor-pointer'
          @click='logoutApp'>
          logout
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  name: 'Koa2022LayoutFooter',
  computed: {
    ...mapState('users', [
      'hasValidToken',
    ]),
    ...mapGetters('events', [
      'eventConfigLogoImageUrl',
    ]),
  },
  methods: {
    ...mapActions('users', [
      'logout',
      'checkTokenStatus',
    ]),
    logoutApp () {
      this.logout().then(() => {
        this.$router.push({name: 'Login'})
      })
    },
    goToHome () {
      this.$router.push({name: 'Home'}).catch(() => {})
    },
  },
  created () {
    this.checkTokenStatus()
  }
}
</script>
